<template>
  <div class="container-fluid">
    <customer-form
      :loading="loading"
      :customerData="customer"
      :formErrors="formErrors"
      @customerSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
      @onCloseCustomerModal="onCloseCustomerModal"
    />
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import { mapGetters } from "vuex";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultCustomer from "../defaultCustomer";
import CustomerForm from "../partials/CustomerForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    CustomerForm,
  },

  mixins: [alertLeave],

  computed: {
    ...mapGetters("organizations", {
      globalOrganization: "globalOrganization",
    }),
  },

  data() {
    let customerData = cloneDeep(defaultCustomer);
    customerData = this.$fillUserOrganizationData(customerData);
    return {
      customer: customerData,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.customer.organization = this.globalOrganization;
  },

  methods: {
    async handleSubmit(customer) {
      this.loading = true;

      const customerData = cloneDeep(customer);
      delete customerData.id;

      try
      {
        await this.$store.dispatch("customers/add", customerData);
      
        this.$notify({
          type: "success",
          message: this.$t("CUSTOMERS.CUSTOMER_ADDED"),
        });
      
        const customer = await this.$store.getters["customers/customer"];
        this.$emit("onViewCustomer", customer, true);
        this.loading = false;
      }
      catch (error)
      {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
    onCloseCustomerModal() {
      this.$emit("onCloseCustomerModal");
    },
  },
};
</script>
