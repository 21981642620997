<template>
  <div class="kw-tabs-wrapper-content">
    <h3>{{ $t("CUSTOMERS.PAYMENT_MODE") }}</h3>
    <div class="customer-payment-infos">
      <div class="customer-payment-infos-list">
        <ul>
          <li>{{ $t("COMMON.BILLING_ADDRESS") }}</li>
          <li>{{ this.customer.billing_address ?? "N/A" }}</li>
        </ul>
      </div>
      <credit-card-selector :customer-id="this.customer.id"/>
    </div>
  </div>
</template>
<script>
import { Button } from "element-ui";
import CreditCardSelector from "@/components/CreditCardSelector.vue";
export default {
  name: "customer-view-payment",

  props: ["customer"],

  components: {
    CreditCardSelector,
    [Button.name]: Button,
  },

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {},
};
</script>
