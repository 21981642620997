<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!customer">
      <span class="loader"></span>
    </span>
    <div v-if="customer" class="kw-tabs-wrapper">
      <div class="view-details-header">
        <div class="view-details-header-left">
          <h2>{{ customerFullName }}</h2>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img src="/img/kw-home.svg" alt="icon" />
                <span>{{ $t("CUSTOMERS.CUSTOMERS_LIST") }}</span>
              </li>
              <li>
                <span>{{ customerFullName }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="view-details-header-right">
          <button
            class="delete"
            @click="deleteCustomer"
            v-if="$currentUserCan($permissions.PERM_DELETE_CUSTOMERS)"
          >
            <i class="far fa-trash-alt"></i>
            <span>{{ $t("COMMON.DELETE_INFOS") }}</span>
          </button>
          <button
            class="edit"
            @click="editCustomer"
            v-if="$currentUserCan($permissions.PERM_EDIT_CUSTOMERS)"
          >
            <i class="far fa-edit"></i>
            <span>{{ $t("COMMON.EDIT_INFOS") }}</span>
          </button>
        </div>
      </div>
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav kw-tabs"
        value="customer"
      >
        <tab-pane title="customer" id="1" :active="true">
          <span slot="title">
            {{ $t("CUSTOMERS.CUSTOMER_INFORMATION") }}
          </span>
          <customer-view-global :customer="customer" @onContractUpdated="get" />
        </tab-pane>
        <tab-pane title="customer" id="2">
          <span slot="title">
            {{ $t("BOOKINGS.BOOKING_LIST") }}
          </span>
          <customer-view-history :customer-id="customer.id"/>
        </tab-pane>

        <tab-pane title="customer" id="999">
          <span slot="title">
            {{ $t("CUSTOMERS.PAYMENT_MODE") }}
          </span>
          <customer-view-payment v-if="customer" :customer="customer" />
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert2";
import { cloneDeep } from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { DUPLICATED_DEFAULT_CODE } from "@/constants/spots";
import { Tabs, TabPane } from "@/components";
//import SpotViewGallery from "../partials/SpotViewGallery";
import CustomerViewGlobal from "../partials/CustomerViewGlobal.vue";
import CustomerViewHistory from "../partials/CustomerViewHistory.vue";
import CustomerViewPayment from "../partials/CustomerViewPayment.vue";
import CreditCardSelector from "@/components/CreditCardSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    CreditCardSelector,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    //SpotViewGallery,
    CustomerViewGlobal,
    CustomerViewHistory,
    CustomerViewPayment,
  },

  mixins: [],

  props: {
    customerId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      customer: null,
    };
  },

  computed: {
    customerFullName() {
      return this.customer?.firstname + ' ' + this.customer?.lastname ;
    }
  },

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch("customers/get", this.customerId);
        this.customer = this.$store.getters["customers/customer"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    editCustomer() {
      this.$emit("onEditCustomer", this.customer);
    },

    deleteCustomer() {
      this.$emit("onDeleteCustomer", this.customer);
    },
  },
};
</script>
